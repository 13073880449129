.@{class-prefix}-transaction-content {
    .ant-list-items {
        display: contents;
    }
    .ant-card-body {
        padding: 5px
    }
    .@{class-prefix}-scroll {
        height: 550px;
        overflow-y: scroll;
        overflow-x: hidden;

    }
    ::-webkit-scrollbar {
        width: 7px;
    }
    ::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 7x;
        border-radius: 7px;
        background: #c1c1c1;
    }

    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 55px;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
        position: relative;
        display: inline-block;
        display: flex;
        flex: auto;
        align-self: stretch;
        white-space: nowrap;
        overflow: visible;
        transform: translate(0);
    }

}


.@{class-prefix}-vertical-scroll{
    .@{class-prefix}-scroll {
        height: 550px;
        overflow-y: hidden;
        overflow-x: scroll;

    }
}

::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 7x;
    border-radius: 7px;
    background: #c1c1c1;
}